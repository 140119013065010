import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';

// login routing
const HomePage = Loadable(lazy(() => import('views/landing-page/home')));
const CompanyPage = Loadable(lazy(() => import('views/landing-page/company')));
const PlatformPage = Loadable(lazy(() => import('views/landing-page/platform')));
const PrivacyPage = Loadable(lazy(() => import('views/landing-page/privacy')));
const ElectronicDisclaimerPage = Loadable(lazy(() => import('views/landing-page/electronicCommunicationDisclaimer')));
const ServicePage = Loadable(lazy(() => import('views/landing-page/service')));
const CookiePage = Loadable(lazy(() => import('views/landing-page/cookies')));
// const CareersPage = Loadable(lazy(() => import('views/landing-page/careers')));
// const ProductPage = Loadable(lazy(() => import('views/landing-page/product')));
const InsightPage = Loadable(lazy(() => import('views/landing-page/insight')));

// const ProductTailor = Loadable(lazy(() => import('views/landing-page/productTailor')));
// const ProductExposure = Loadable(lazy(() => import('views/landing-page/productDirectionalExposure')));
// const ProductYield = Loadable(lazy(() => import('views/landing-page/productYield')));

// const LoginPage = Loadable(lazy(() => import('views/landing-page/login')));
// const SignUpPage = Loadable(lazy(() => import('views/landing-page/signUp')));
// const IndividualPage = Loadable(lazy(() => import('views/landing-page/individual')));
// const InstitutionPage = Loadable(lazy(() => import('views/landing-page/institution')));
// const SignUpSuccess = Loadable(lazy(() => import('views/landing-page/signUpSuccess')));
// const SignUpSuccessInstitution = Loadable(lazy(() => import('views/landing-page/signUpSuccessInstitution')));

// ==============================|| AUTH ROUTING ||============================== //

const LandingPageRoutes = {
    path: '/',
    element: <MinimalLayout />,

    children: [
        {
            index: true,

            element: <HomePage />
        },
        {
            path: '/home',
            element: <HomePage />
        },
        {
            path: '/company',
            element: <CompanyPage />
        },
        {
            path: '/platform',
            element: <PlatformPage />
        },
        {
            path: '/privacy-policy',
            element: <PrivacyPage />
        },
        {
            path: '/electronic-communication',
            element: <ElectronicDisclaimerPage />
        },
        {
            path: '/cookies-policy',
            element: <CookiePage />
        },

        {
            path: '/terms-of-service',
            element: <ServicePage />
        },
        // {
        //     path: '/careers',
        //     element: <CareersPage />
        // },
        // {
        //     path: '/login',
        //     element: <LoginPage />
        // },
        {
            path: '/insights',
            element: <InsightPage />
        },
        // {
        //     path: '/sign',
        //     element: <SignUpPage />,
        //     children: []
        // },
        // {
        //     path: '/sign/individual',
        //     element: <IndividualPage />
        // },
        // {
        //     path: '/sign/institution',
        //     element: <InstitutionPage />
        // },
        // {
        //     path: '/sign/individual/success',
        //     element: <SignUpSuccess />
        // },
        // {
        //     path: '/sign/institution/success',
        //     element: <SignUpSuccessInstitution />
        // },
        // {
        //     path: '/products',
        //     element: <ProductPage />,

        //     children: [
        //         {
        //             index: true,

        //             element: <ProductYield />
        //         },
        //         {
        //             path: 'tailor-strategy',
        //             element: <ProductTailor />
        //         },
        //         {
        //             path: 'directional-exposure-strategy',
        //             element: <ProductExposure />
        //         },
        //         {
        //             path: 'yield-enhancement-strategy',
        //             element: <ProductYield />
        //         }
        //     ]
        // },
        {
            path: '*',

            element: <Navigate to="/" />
        }
    ]
};

export default LandingPageRoutes;
