// action - state management
import * as actionTypes from './actions';
import { DefaultRootStateProps } from 'types';

const HeaderHeight = 72.5; // 112.5;
const NoticeBarHeight = 40;

export const initialState: DefaultRootStateProps['pageSetting'] = {
    isShowNoticeBar: false,
    headerHeight: HeaderHeight,
    toolBarHeight: HeaderHeight - NoticeBarHeight
};

export interface PageSettingReducerActionProps {
    type: string;
    payload?: any; // todo type define
}

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const pageSettingReducer = (state = initialState, action: PageSettingReducerActionProps) => {
    switch (action.type) {
        case actionTypes.CLOSE_NOTICE_BAR:
            return {
                ...state,
                isShowNoticeBar: false,
                headerHeight: HeaderHeight - NoticeBarHeight
            };

        default:
            return state;
    }
};

export default pageSettingReducer;
