// material-ui
// import { useTheme } from '@mui/material/styles';
import * as React from 'react';

import {
    Box,
    Button,
    Stack,
    Paper,
    MenuList,
    MenuItem,
    Typography,
    Divider,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    ListItemIcon,
    List,
    ListItem,
    ListItemText,
    ButtonBase,
    Link,
    IconButton,
    SwipeableDrawer,
    Collapse,
    ClickAwayListener,
    Grid,
    Select,
    InputBase,
    FormControl
} from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';

// import { Link } from 'react-router-dom';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MenuIcon from '@mui/icons-material/Menu';

// import Logo from 'assets/images/tdxLogo.jpg';
import MenuBg from 'assets/images/home/menu-personal-bg.png';
import MenuArtA from 'assets/images/home/menu-img1.png';
import MenuArtB from 'assets/images/home/menu-img2.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import MenuIcon from 'assets/images/home/menu-icon.png';
import { useNavigate } from 'react-router-dom';
import { useI18n } from 'hooks/useI18n';
import { withStyles } from '@mui/styles';
// import BilingIcon from 'assets/images/icons/bilingual-login.svg';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3)
        }
    },
    input: {
        //   borderRadius: 0,
        //   position: 'relative',
        //   backgroundColor: theme.palette.background.paper,
        //   border: 'none',
        //   fontSize: 16,
        //   padding: '10px 26px 10px 12px',
        //   transition: theme.transitions.create(['border-color', 'box-shadow']),
        //   // Use the system font instead of the default Roboto font.

        // fontSize: '1rem',
        // color: 'black',
        // fontFamily: 'GothamBold',
        // fontWeight: '500'
        fontSize: '1rem',
        color:  'black',
        fontFamily: 'GothamBold',
        fontWeight: 500,

        '&:focus': {},
        '@media (max-width: 960px)':{
            color:"#001fa1",
            fontSize:'14px'
        }
    }
}))(InputBase);

const BilingSwitchBtn = () => {
    console.log('');
    const { setI18n, locale } = useI18n();
    const toggleLanguage = (a:any) => {
        console.log({ a });
        // if (locale == 'en') {
        //     setI18n('zh');
        // } else {
        //     setI18n('en');
        // }
        setI18n(a?.target?.value ?? "en")
    };
    return (
        <Box
            // onClick={toggleLanguage}
            sx={{
                display: 'flex',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                ml: '22px',
                px: {
                    md: 0,
                    lg: 2
                }
            }}
        >
            {/* <img src={BilingIcon} />
            <Typography sx={{ ml: '2px', fontFamily: 'GothamBold', fontWeight: 'Bold', fontSize: '.875rem'}}>
                {i18n.aliasNames[i18n.locale]}
            </Typography> */}
            <FormControl sx={{}}>
                <Select

                    value={locale}
                    onChange={toggleLanguage}
                    input={<BootstrapInput />}
                >
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="zh">繁體中文</MenuItem>
                    <MenuItem value="cn">简体中文</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

const PopoverMenu = ({ handleClick }: any) => (
    <Paper
        elevation={2}
        sx={{
            py: 0,
            width: '100%',
            height: '450px',
            position: 'absolute',
            left: 0,
            top: '72.5px',
            borderRadius: 0
        }}
    >
        <Stack flexDirection="row" justifyContent="space-between" sx={{ height: '450px' }}>
            <MenuList sx={{ width: '25%', bgcolor: 'primary.dark' }}>
                <MenuItem sx={{}}>
                    <ListItemIcon />
                    <Typography variant="h2" sx={{ color: 'white', fontSize: '1.58rem' }}>
                        Products
                    </Typography>
                </MenuItem>
                <Divider />
                {/* <MenuItem>
                    <ListItemIcon />
                    <Typography variant="content" sx={{ color: 'white', fontSize: '1rem' }}>
                        Personal Finance
                    </Typography>
                </MenuItem> */}
                <MenuItem>
                    <ListItemIcon />
                    <Link
                        variant="content"
                        sx={{ color: 'white', fontSize: '1rem', textDecoration: 'none' }}
                        href="/products/yield-enhancement-strategy"
                        underline="hover"
                    >
                        Yield Enhancement
                    </Link>
                </MenuItem>
                <MenuItem>
                    <ListItemIcon />
                    <Link
                        variant="content"
                        sx={{ color: 'white', fontSize: '1rem', textDecoration: 'none' }}
                        href="/products/directional-exposure-strategy"
                        underline="hover"
                    >
                        Directional Exposure
                    </Link>
                </MenuItem>
                <MenuItem>
                    <ListItemIcon />
                    <Link
                        variant="content"
                        sx={{ color: 'white', fontSize: '1rem', textDecoration: 'none' }}
                        href="/products/tailor-strategy"
                        underline="hover"
                    >
                        Tailored Strategies
                    </Link>
                </MenuItem>
            </MenuList>
            <Card sx={{ borderRadius: 0, p: 2 }}>
                <CardActionArea>
                    {/* eslint-disable-next-line */}
                    <CardMedia component="img" height="260" image={MenuBg} alt="news" />
                    <CardContent sx={{ height: '90px' }}>
                        <Typography
                            sx={{ mb: 3, fontSize: '1rem' }}
                            variant="h2"
                            color="primary"
                            component="div"
                        >
                            TDX Strategies offers cutting edge investment solutions on digital assets. Speak
                            to us and find out how we can meet your financial goal.
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            <List
                sx={{
                    width: '30%',
                    backgroundColor: '#5C5C5C',
                    px: {
                        sx: 2,
                        lg: 4
                    },
                    py: 6
                }}
            >
                <ListItem alignItems="flex-start">
                    <Typography variant="content" sx={{ fontSize: '1rem', color: 'white' }}>
                        FEATURED
                    </Typography>
                </ListItem>
                <Divider />
                <ListItem>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item md={4} lg={5}>
                            <img alt="" width="100%" src={MenuArtB} />
                        </Grid>
                        <Grid item md={8} lg={7}>
                            <ListItemText
                                primary={
                                    <Typography
                                        component="p"
                                        variant="content"
                                        sx={{ fontSize: '1rem', color: 'white' }}
                                    >
                                        IDEAS
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        component="p"
                                        variant="content"
                                        sx={{ fontSize: '1rem', color: 'white', mt: 1 }}
                                    >
                                        Yield Farming as the next alternative?
                                    </Typography>
                                }
                            />
                        </Grid>
                    </Grid>
                </ListItem>
                <ListItem>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item md={4} lg={5}>
                            <img alt="" width="100%" src={MenuArtA} />
                        </Grid>
                        <Grid item md={8} lg={7}>
                            <ListItemText
                                primary={
                                    <Typography
                                        component="p"
                                        variant="content"
                                        sx={{ fontSize: '1rem', color: 'white' }}
                                    >
                                        SUSTAINABILITY
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        component="p"
                                        variant="content"
                                        sx={{ fontSize: '1rem', color: 'white', mt: 1 }}
                                    >
                                        Scaling your investment portfolio to maximize growth.
                                    </Typography>
                                }
                            />
                        </Grid>
                    </Grid>
                </ListItem>
            </List>
            {/* <Card sx={{ width: '25%', borderRadius: 0, backgroundColor: '#5C5C5C' }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        FEATURED
                    </Typography>
                    <Divider />
                    <Typography variant="h5" component="div">
                        fdfdfdfdf
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        adjective
                    </Typography>
                    <Typography variant="content"> well meaning and kindly. </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small">Learn More</Button>
                </CardActions>
            </Card> */}
        </Stack>
        <CloseRoundedIcon
            onClick={handleClick}
            sx={{
                position: 'absolute',
                top: '2rem',
                right: '2rem',
                color: 'white',
                fontSize: '2rem',
                cursor: 'pointer'
            }}
        />
    </Paper>
);

const NavItem = (props: any) => {
    const { name, handleClick } = props;
    const url = `/${name.toLowerCase()}`;
    const { i18n } = useI18n();
    let delayTimer: any = null;

    const delayAction = (e: any) => {
        if (delayTimer) {
            return;
        }
        delayTimer = setTimeout(() => {
            handleClick && handleClick(e);
            if (delayTimer) {
                clearTimeout(delayTimer);
                delayTimer = null;
            }
        }, 700);
    };

    const onMouseLeave = () => {
        console.log('leave');
        if (delayTimer) {
            clearTimeout(delayTimer);
            delayTimer = null;
        }
    };

    return (
        <Button
            onMouseOver={delayAction}
            onMouseLeave={onMouseLeave}
            variant="text"
            component={Link}
            href={url}
            sx={{
                fontSize: '1rem',
                color: 'black',
                fontFamily: 'GothamBold',
                fontWeight: '500'
            }}
        >
            {i18n.t(name.toLowerCase())}
        </Button>
    );
};

const DrawerMenuIcon = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setIsOpen(open);
    };

    const navigate = useNavigate();

    const handleMobileMenuSelect = (e: any, nodeId: string) => {
        switch (nodeId) {
            case '1':
                navigate('/company');
                break;
            case '2':
                navigate('/products');
                break;
            case '3':
                navigate('/products/yield-enhancement-strategy');
                break;
            case '4':
                navigate('/products/directional-exposure-strategy');
                break;
            case '5':
                navigate('/products/tailor-strategy');
                break;
            case '6':
                navigate('/insights');
                break;
            case '7':
                navigate('/');
                break;
            case '8':
                navigate('/platform');
                break;
            default:
                console.log(e);
        }
    };

    return (
        <>
            <IconButton
                color="inherit"
                size="large"
                onClick={toggleDrawer(true)}
                sx={{
                    display: {
                        md: 'none'
                    },
                    p: 0,
                    ml: 1
                }}
            >
                <MenuIcon
                    sx={{
                        '& .MuiSvgIcon-root': {
                            fontSize: '2rem'
                        }
                    }}
                />
            </IconButton>

            <SwipeableDrawer
                anchor="right"
                open={isOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                {/* <List>
                    <ListItem button>
                        <ListItemIcon>.</ListItemIcon>
                        <ListItemText
                            primary={
                                <Link underline="none" href="/">
                                    Home
                                </Link>
                            }
                        />
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon>.</ListItemIcon>
                        <ListItemText
                            primary={
                                <Link underline="none" href="/company">
                                    Company
                                </Link>
                            }
                        />
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon>.</ListItemIcon>
                        <ListItemText
                            primary={
                                <Link underline="none" href="/product">
                                    Product
                                </Link>
                            }
                        />
                    </ListItem>


                    <ListItem button>
                        <ListItemIcon>.</ListItemIcon>
                        <ListItemText
                            primary={
                                <Link underline="none" href="/insights">
                                    Insights
                                </Link>
                            }
                        />
                    </ListItem>
                </List> */}

                <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    // defaultExpanded={['2']}
                    onNodeSelect={handleMobileMenuSelect}
                    sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                >
                    <TreeItem nodeId="7" label="Home" />
                    <TreeItem nodeId="1" label="Company" />

                    {/* <TreeItem nodeId="2" label="Product">
                        <TreeItem nodeId="3" label="Yield Enhancement" />
                        <TreeItem nodeId="4" label="Directional Exposure" />
                        <TreeItem nodeId="5" label="Tailored Strategies" />
                    </TreeItem> */}
                    <TreeItem nodeId="8" label="Platform" />
                    <TreeItem nodeId="6" label="Insights" />
                    <Box mt={5}>
                        <BilingSwitchBtn />
                    </Box>
                </TreeView>
            </SwipeableDrawer>
        </>
    );
};
const Header = () => {
    // const theme = useTheme();

    const [anchorEl, setAnchorEl] = React.useState('');

    // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     console.log('aaa', event);
    //     setAnchorEl('product');
    // };

    const handleClose = () => {
        setAnchorEl('');
    };

    const goToTele = () => {
        window.open(`${process.env.REACT_APP_BASE_URL}/tdx/login`);
    };

    const { i18n } = useI18n();

    const open = Boolean(anchorEl);
    // const ProductMenu = () => {
    //     if (open) {
    //         return <PopoverMenu handleClick={handleClose} />;
    //     }
    //     return null;
    // };
    // const id = open ? 'product' : undefined;

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: {
                        md: 0,
                        lg: 2
                    }
                }}
            >
                <ButtonBase disableRipple component={Link} href="/home">
                    <img src="/tdxLogo.svg" alt="tdx" width={150} />
                </ButtonBase>
                {/* <ButtonBase disableRipple component={Link} href="/home">
                    <img src="/tdxLogo.svg" alt="tdx" width={150} />
                </ButtonBase>
                <ButtonBase disableRipple component={Link} href="/home">
                    <img src="/tdxLogo.png" alt="tdx" width={150} />
                </ButtonBase> */}
                <Stack
                    sx={{
                        flexGrow: 1,
                        ml: 11,
                        display: { xs: 'none', md: 'flex' },
                        justifyContent: 'flex-end'
                    }}
                    direction="row"
                    spacing={4}
                    pr={4}
                >
                    <NavItem name="Company" />
                    <NavItem name="Platform" />
                    {/* <NavItem name="Products" aria-describedby={id} handleClick={handleClick} /> */}

                    {/* <NavItem name="Platform" /> */}
                    <NavItem name="Insights" />
                </Stack>
                <Stack direction="row" alignItems="center">
                    <Button
                        // component={Link}
                        variant="text"
                        // href="/login"
                        onClick={goToTele}
                        sx={{
                            fontSize: '1rem',
                            lineHeight: '1rem',
                            fontFamily: 'GothamBold',
                            fontWeight: '500',
                            color: 'black',
                            display: {
                                xs: 'none',
                                md: 'block'
                            }
                        }}
                    >
                        {i18n.t('login')}
                    </Button>
                    <Button
                        variant="contained"
                        href="/tdx/register"
                        component={Link}
                        sx={{
                            padding: {
                                md: '.5rem 1rem',
                                sx: 'px 1rem'
                            },
                            lineHeight: '100%',
                            marginLeft: '2rem',
                            fontSize: '.875rem',
                            fontFamily: 'GothamBold',
                            fontWeight: 'Bold',
                            textTransform: 'none',
                            display: {
                                xs: 'none',
                                md: 'block'
                            }
                        }}
                    >
                        {i18n.t('getStarted')}
                    </Button>
                    <Box sx={{ display: { xs: 'none', md: 'block' } }} ml={2}>
                        <BilingSwitchBtn />
                    </Box>
                    <Button
                        variant="text"
                        href="/tdx/register"
                        component={Link}
                        sx={{
                            fontSize: '.875rem',
                            lineHeight: '1rem',
                            fontFamily: 'GothamBold',
                            fontWeight: 'Bold',
                            display: {
                                xs: 'block',
                                md: 'none'
                            }
                        }}
                    >
                        {i18n.t('register')}
                    </Button>
                    {/* <Button
                        variant="contained"
                        href="/sign"
                        component={Link}
                        sx={{
                            padding: {
                                md: '.5rem 1rem',
                                sx: 'px 1rem'
                            },
                            lineHeight: '100%',
                            marginLeft: '2rem',
                            fontSize: '.875rem',
                            fontFamily: 'GothamBold',
                            fontWeight: 'Bold',
                            textTransform: 'none',
                            display: {
                                xs: 'block',
                                md: 'none'
                            }
                        }}
                    >
                        Register
                    </Button> */}
                    <Button
                        variant="contained"
                        onClick={goToTele}
                        component={Link}
                        sx={{
                            padding: {
                                md: '.5rem 1rem',
                                sx: 'px 1rem'
                            },
                            lineHeight: '100%',
                            marginLeft: '.875rem',
                            fontSize: '.875rem',
                            fontFamily: 'GothamBold',
                            fontWeight: 'Bold',
                            textTransform: 'none',
                            display: {
                                xs: 'block',
                                md: 'none'
                            }
                        }}
                    >
                        {i18n.t('login')}
                    </Button>
                    {/* <IconButton
                        color="inherit"
                        size="large"
                        sx={{
                            display: {
                                lg: 'none'
                            },
                            p: 0
                        }}
                    >
                        <img alt="" src={MenuIcon} />
                    </IconButton> */}
                    <DrawerMenuIcon />
                </Stack>
            </Box>
            <ClickAwayListener onClickAway={handleClose}>
                <Collapse in={open}>
                    <PopoverMenu handleClick={handleClose} />
                </Collapse>
            </ClickAwayListener>
        </>
    );
};

export default Header;
