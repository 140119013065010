import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from 'store/actions';
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, Typography, Stack } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// import NavigationIcon from '@mui/icons-material/Navigation';
// import { animateScroll } from 'react-scroll';
// project import
import Header from './Header';
import Footer from './Footer';

const Main = styled('main')({
    width: '100%',
    flexGrow: 1
});

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
    const theme = useTheme();

    const dispatch = useDispatch();
    const handleCloseNoticeBar = () => {
        dispatch({ type: actionTypes.CLOSE_NOTICE_BAR });
    };
    const pageSetting = useSelector((state: any) => state.pageSetting);

    return (
        <Box
            sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%', flexGrow: 1 }}
        >
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={1}
                sx={{
                    bgcolor: theme.palette.background.default
                }}
            >
                {/* top notice bar */}
                {pageSetting.isShowNoticeBar && (
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            bgcolor: theme.palette.primary.main,
                            height: 40,
                            position: 'relative'
                        }}
                    >
                        <Typography
                            variant="h4"
                            align="center"
                            component="span"
                            sx={{
                                color: '#fff',
                                display: 'inlin-block',
                                alignItems: 'center'
                            }}
                        >
                            placeholder
                        </Typography>

                        <CloseRoundedIcon
                            sx={{
                                position: 'absolute',
                                right: '1rem',
                                color: 'white',
                                fontSize: '2rem',
                                cursor: 'pointer'
                            }}
                            onClick={handleCloseNoticeBar}
                        />
                    </Stack>
                )}

                <Toolbar
                    sx={{
                        height: 72.5
                    }}
                >
                    <Header />
                </Toolbar>
            </AppBar>
            {/* offset */}
            <Box
                sx={{
                    height: pageSetting.headerHeight,
                    width: '100%'
                }}
            />

            <Main>
                <Outlet />
            </Main>

            <Footer />
            {/* <Fab sx={{ position: 'fixed', right: 12, bottom: 12 }} onClick={() => animateScroll.scrollTo(0)}>
                <NavigationIcon sx={{ color: 'primary.main' }} />
            </Fab> */}
        </Box>
    );
};

export default MinimalLayout;
