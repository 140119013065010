import { useRoutes } from 'react-router-dom';

// routes
// import MainRoutes from './MainRoutes';
// import LoginRoutes from './LoginRoutes';
// import AuthenticationRoutes from './AuthenticationRoutes';
import LandingPageRoutes from './LandingPageRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    // return useRoutes([LandingPageRoutes, MainRoutes, LoginRoutes, AuthenticationRoutes]);

    return useRoutes([LandingPageRoutes]);
}
