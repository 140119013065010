import React, { useState, useEffect } from 'react';
import { I18n } from 'i18n-js';
 
import en from 'utils/locales/en.json';
import zh from 'utils/locales/zh.json';
import cn from 'utils/locales/cn.json';
import { I18nContext } from 'hooks/useI18n';

declare module 'i18n-js' {
    interface I18n {
        aliasNames: any;
        aliasNames2: any;
        aliasNames3: any;
    }
}

const i18n = new I18n({
    en,
    zh,
    cn
});

i18n.aliasNames = {
    en: 'English',
    zh: '繁體中文',
    cn:'简体中文'
};

i18n.aliasNames2 = {
    en: 'en-US',
    zh: 'zh-Hant',
    cn:'zh-Hans'
};
i18n.aliasNames3 = {
    en: 'En',
    zh: '繁'
};

 

// ==============================|| LOCALIZATION ||============================== //
export interface LocalsProps {
    children: React.ReactNode;
}

const Locales = ({ children }: LocalsProps) => {
    const [i18nLocale, setI18nLocal] =  useState('en');
    const i18nValue = {
        setI18n: (lang = 'en') => {
            setI18nLocal(lang);
            i18n.locale = lang;
        },
        i18n,
        locale: i18nLocale
    };

    useEffect(() => {
        const currentLang = localStorage.getItem("BlazorCulture")
        if(!currentLang) {
            localStorage.setItem("BlazorCulture","en-US")
            return
        }

        if(currentLang === "en-US"){
            i18nValue.setI18n("en")
        }

        if(currentLang === "zh-Hant"){
            i18nValue.setI18n("zh")
        }

        if(currentLang === "zh-Hans"){
            i18nValue.setI18n("cn")
        }
        

    }, []);

    useEffect(() => {
        // todo: save to localstorage
        if(i18nLocale == "en"){
            localStorage.setItem("BlazorCulture","en-US")
        }else if(i18nLocale == "zh"){
            localStorage.setItem("BlazorCulture","zh-Hant")
        }else {
            localStorage.setItem("BlazorCulture","zh-Hans")
        }
    }, [i18nLocale]);

    return (
        <>
            <I18nContext.Provider value={i18nValue}>{children}</I18nContext.Provider>
        </>
    );
};

export default Locales;
