/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { CustomTypography } from 'themes/types';

export default function themeTypography(theme: CustomTypography): TypographyOptions {
    return {
        fontFamily: theme?.customization?.fontFamily!,
        h6: {
            fontWeight: 500,
            color: theme.heading!,
            fontSize: '0.75rem'
        },
        h5: {
            fontSize: '0.875rem',
            color: theme.heading!,
            fontWeight: 500
        },
        h4: {
            fontSize: '1rem',
            color: theme.heading!,
            fontWeight: 600
        },
        h3: {
            fontSize: '1.25rem',
            color: theme.heading!,
            fontWeight: 600
        },
        h2: {
            fontSize: '1.5rem',
            color: theme.heading!,
            fontWeight: 700
        },
        h1: {
            fontSize: '2.125rem',
            color: theme.heading!,
            fontWeight: 700
        },
        subtitle1: {
            fontSize: '0.875rem',
            fontWeight: 500,
            color: theme?.customization?.navType === 'dark' ? theme.heading! : theme.textDark!
        },
        subtitle2: {
            fontSize: '0.75rem',
            fontWeight: 400,
            color: theme.darkTextSecondary!
        },
        caption: {
            fontSize: '0.75rem',
            color: theme.darkTextSecondary!,
            fontWeight: 400
        },
        body1: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.334em'
        },
        body2: {
            letterSpacing: '0em',
            fontWeight: 400,
            lineHeight: '1.5em',
            color: theme.darkTextPrimary!
        },
        button: {
            fontSize: '1.16rem',
            fontWeight: 700,
            fontFamily: 'GothamBold'
        },
        customInput: {},
        mainContent: {},
        menuCaption: {},
        subMenuCaption: {},
        commonAvatar: {},
        smallAvatar: {},
        mediumAvatar: {},
        largeAvatar: {},
        heading1: {
            fontSize: '1.875rem', // page title
            fontWeight: 700,
            color: theme.darkTextSecondary!,
            fontFamily: 'GothamBold',
            lineHeight: '125%'
        },
        heading2: {
            fontSize: '1.5rem', // section title
            color: theme.darkTextSecondary!,
            fontWeight: 700,
            fontFamily: 'GothamBold',
            lineHeight: '125%'
        },
        subHead: {
            fontSize: '1.1875rem', // section title
            color: theme.darkTextSecondary!,
            fontWeight: 700,
            fontFamily: 'GothamBold',
            lineHeight: '125%'
        },
        content: {
            fontSize: '1rem',
            fontWeight: 400,
            fontFamily: 'GothamBook',
            color: theme.textDark,
            lineHeight: '135%'
        },
        contentSmall: {
            fontSize: '14px',
            fontWeight: 400,
            fontFamily: 'GothamBook',
            color: theme.textDark
        },

        menu: {
            fontSize: '14px', // section title
            color: theme.darkTextSecondary!,
            fontWeight: 700,
            fontFamily: 'GothamBold'
        },
        contentBold: {}
    };
}
